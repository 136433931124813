import styled from "styled-components"

export const LocationWrapperShortened = styled.div`
  padding: 0.3rem 0;
  text-align: center;
`

export const LocationWrapperExpanded = styled.div`
  padding: 0 6px;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
`

export const Circuit = styled.span`
  font-size: 90%;
  font-weight: bold;
`
